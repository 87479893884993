import React, { useEffect} from "react";
// import React, { useEffect, useState} from "react";
// import { Link, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import {isMobile, isAndroid, isIOS} from 'react-device-detect';


const RenderContent = () => {
    
    // console.log("device-detect.isMObile=>>>>>>>", isMobile);
    // console.log("device-detect.isAndroid=>>>>>>>", isAndroid);
    // console.log("device-detect.isIOS=>>>>>>>", isIOS);

    // const [url, seturl] = useState("");

    useEffect(()=>{
        // const landingPage='https://master.d1wkb3vfvp4n6y.amplifyapp.com/';
        const landingPage='https://szlak.mlawa.pl';
        // const googlePlay='https://play.google.com/';
        // const googlePlay='https://play.google.com/store/apps/details?id=pl.globallab.bw1920';
        const googlePlay='https://play.google.com/store/apps/details?id=pl.globallab.mlawa';
        // const appleItunes='https://itunes.apple.com/';
        // const appleItunes='https://apps.apple.com/us/app/szlak-bitwy-warszawskiej/id1546989767';
        const appleItunes='https://apps.apple.com/pl/app/m%C5%82awski-szlak-turystyczny/id1634486664?l=pl';
        
        if (isMobile) {

            if(isAndroid){
                window.location.replace(googlePlay);
            }
            else if(isIOS){
                window.location.replace(appleItunes);                
            }
            else window.location.replace(landingPage);
        }
        else window.location.replace(landingPage);


    },[]);

    return(
        <div>Detecting Your device...</div>

        
    )
};

export default RenderContent;